import React, { useContext, useEffect } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { Tab, Tabs } from '@mui/material';
import {
  Article,
  Balance,
  Description,
  Equalizer,
  Extension,
  IntegrationInstructions,
  PermMedia,
  PrecisionManufacturing,
  Settings,
} from '@mui/icons-material';
import { AuthContext } from 'contexts/AuthContext';
import { GridContext } from 'contexts/GridContext';
import { DropsContext } from 'contexts/DropsContext';
import { ProdutosContext } from 'contexts/ProdutosContext';
import { RelatoriosContext } from 'contexts/RelatoriosContext';
import { useModal, useDialog } from 'components/Modals';
import DeleteModal from 'components/Modals/DeleteModal';
import Container from 'components/Container';
import Header from 'components/Header';
import Card from 'components/Card';
import CustomTabs from './Tabs';

const Detalhes = () => {
  const { onChangeTab, tab } = useOutletContext();
  const { id } = useParams();
  const { user } = useContext(AuthContext);
  const { drops } = useContext(DropsContext);
  const { deleteGrid } = useContext(GridContext);
  const { getURLRelatorio } = useContext(RelatoriosContext);
  const { openModal, closeModal } = useModal();
  const { closeDialog } = useDialog();
  const {
    produto,
    postMidia,
    putLoading,
    getLoading,
    getProduto,
    postProduto,
    postLoading,
    deleteMidia,
    putProdutoCusto,
    postCopiaProcesso,
    postCopiaEstrutura,
    postIntegracao,
    deleteIntegracao,
  } = useContext(ProdutosContext);
  const disableActions = user?.permissoes?.block_altera_produto;
  const descricao =
    produto?.ProdutoDescricaos?.find(
      (d) => d?.tipo === 'COMPLETA' || d?.tipo === 'REDUZIDA'
    )?.descricao || '';

  const loadProduto = () => getProduto(id);

  useEffect(() => {
    loadProduto();
    onChangeTab({ tab: tab, key: id });
  }, []);

  const onSubmit = (data) =>
    postProduto({
      data,
      cb: () => {
        loadProduto();
        closeDialog();
      },
    });

  const onDelete = (params) =>
    openModal(
      <DeleteModal
        onSubmit={() =>
          deleteGrid({
            params,
            cb: () => {
              loadProduto();
              closeModal();
            },
          })
        }
      />
    );

  const onSubmitEstrutura = (data) =>
    postCopiaEstrutura({
      data,
      cb: () => {
        loadProduto();
        closeDialog();
      },
    });

  const onSubmitProcesso = (data) =>
    postCopiaProcesso({
      data,
      cb: () => {
        loadProduto();
        closeDialog();
      },
    });

  const onSubmitCusto = (data) =>
    putProdutoCusto({
      data,
      cb: () => {
        loadProduto();
      },
    });

  const onSubmitMidia = (data) =>
    postMidia({
      data,
      cb: () => {
        loadProduto();
      },
    });

  const onSubmitIntegracao = ({ data, path }) =>
    postIntegracao({
      data,
      path,
      cb: () => {
        loadProduto();
        closeDialog();
      },
    });

  const onDeleteIntegracao = ({ id, path }) =>
    openModal(
      <DeleteModal
        onSubmit={() =>
          deleteIntegracao({
            id,
            path,
            cb: () => {
              loadProduto();
              closeModal();
              closeDialog();
            },
          })
        }
      />
    );

  const onDeleteMidia = (id) =>
    openModal(
      <DeleteModal
        onSubmit={() =>
          deleteMidia({
            id,
            cb: () => {
              loadProduto();
              closeModal();
            },
          })
        }
      />
    );

  const tabs = [
    {
      value: 0,
      key: 'Produto',
      label: 'Produto',
      icon: <Settings />,
    },
    {
      value: 1,
      key: 'Descricao',
      label: 'Descrições',
      icon: <Description />,
    },
    {
      value: 2,
      key: 'Midias',
      label: 'Mídias',
      icon: <PermMedia />,
    },
    {
      value: 3,
      key: 'Registros',
      label: 'Registros',
      icon: <Article />,
    },
    {
      value: 4,
      key: 'Similar',
      label: 'Similares',
      icon: <Balance />,
    },
    {
      value: 5,
      key: 'Variacao',
      label: 'Variações',
      icon: <Equalizer />,
      show: produto?.variacao === 'SIM',
    },
    {
      value: 6,
      key: 'Integracoes',
      label: 'Integrações',
      icon: <IntegrationInstructions />,
    },
    {
      value: 7,
      key: 'Estrutura',
      label: 'Estrutura',
      icon: <Extension />,
      show: produto?.Celinha?.estrutura !== 'NAO',
    },
    {
      value: 8,
      key: 'Processos',
      label: 'Processos',
      icon: <PrecisionManufacturing />,
      show: produto?.Celinha?.processo !== 'NAO',
    },
  ];

  const options = [
    {
      name: 'Explosão da Estrutura',
      icon: 'print',
      show: produto?.Celinha?.estrutura !== 'NAO',
      action: () =>
        getURLRelatorio({ data: { codigo: 'PROD000002', prd: id } }),
    },
    {
      name: 'Implosão da Estrutura',
      icon: 'print',
      action: () =>
        getURLRelatorio({ data: { codigo: 'PROD000003', prd: id } }),
    },
  ];

  return (
    <Container>
      <Header titulo="Produto" subtitulo={descricao} outros={options} />
      <Card>
        <Tabs
          variant="fullWidth"
          value={tab}
          onChange={(_, tab) => onChangeTab({ tab, key: id })}
          TabIndicatorProps={{ sx: { display: 'none' } }}
          sx={{
            '& .MuiTabs-flexContainer': {
              flexWrap: 'wrap',
            },
          }}
        >
          {tabs.map(({ show = true, ...t }) => show && <Tab {...t} />)}
        </Tabs>
      </Card>
      <CustomTabs
        tab={tab}
        drops={drops}
        produto={produto}
        getLoading={getLoading}
        disableActions={disableActions}
        loading={postLoading || putLoading}
        onSubmit={onSubmit}
        onDelete={onDelete}
        onDeleteMidia={onDeleteMidia}
        onSubmitMidia={onSubmitMidia}
        onSubmitCusto={onSubmitCusto}
        onSubmitProcesso={onSubmitProcesso}
        onSubmitEstrutura={onSubmitEstrutura}
        onSubmitIntegracao={onSubmitIntegracao}
        onDeleteIntegracao={onDeleteIntegracao}
      />
    </Container>
  );
};

export default Detalhes;
